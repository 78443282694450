import React from 'react';
import Section from './Section';
import FeaturesIcons from './FeaturesIcons';
import './FeaturesSectionIcons.scss';

function FeaturesSectionIcons(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <div className="columns is-vcentered is-variable is-4 is-desktop">
          <div className="column is-12 is-2-desktop">
            <FeaturesIcons
              align="centered"
              items={[
                {
                  title: 'Retail',
                  iconClass: 'fas fa-store',
                  iconColor: 'primary',
                },
                {
                  title: 'Office',
                  iconClass: 'fas fa-building',
                  iconColor: 'primary',
                },
                {
                  title: 'Industrial',
                  iconClass: 'fas fa-industry',
                  iconColor: 'primary',
                },
              ]}
              data-aos="fade-right"
            />
          </div>
          <div className="column" data-aos="fade">
            <figure className="FeaturesSectionIcons__image image">
              <img
                src={props.image}
                alt="Fargo cityscape with the wording: For lease, for sale"
              />
            </figure>
          </div>
          <div className="column is-12 is-2-desktop">
            <FeaturesIcons
              align="centered"
              items={[
                {
                  title: 'Multifamily',
                  iconClass: 'fas fa-hotel',
                  iconColor: 'primary',
                },
                {
                  title: 'Land',
                  iconClass: 'fas fa-map-marked-alt',
                  iconColor: 'primary',
                },
                {
                  title: 'Business sales',
                  iconClass: 'fas fa-briefcase',
                  iconColor: 'primary',
                },
              ]}
              data-aos="fade-left"
            />
          </div>
        </div>
      </div>
    </Section>
  );
}

export default FeaturesSectionIcons;
