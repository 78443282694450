import React from 'react';
import './FeaturesAlternated.sass';

function FeaturesAlternated(props) {
  return (
    <div className="FeaturesAlternated">
      {props.items.map((item, index) => (
        <div
          className="FeaturesAlternated__columns columns is-variable is-8 is-vcentered has-text-centered-mobile"
          key={index}
          data-aos="fade"
        >
          <div className="column is-half">
            <h3 className="FeaturesAlternated__title title has-text-weight-bold is-spaced is-3">
              {item.title}
            </h3>
            <p>{item.description}</p>
          </div>
          <div className="column">
            <figure className="FeaturesAlternated__image image">
              {item.image}
            </figure>
          </div>
        </div>
      ))}
    </div>
  );
}

export default FeaturesAlternated;
