import React from 'react';
import './FeaturesIcons.scss';

function FeaturesIcons(props) {
  const aosProps = {};
  for (const key of Object.keys(props)) {
    if (key.indexOf('data-aos') === 0) {
      aosProps[key] = props[key];
    }
  }

  return (
    <div>
      {props.items.map((item, index) => (
        <div
          className={`FeaturesIcons__feature has-text-${props.align}`}
          {...aosProps}
        >
          <div
            className={`FeaturesIcons__icon icon has-text-${item.iconColor}`}
          >
            <i className={`${item.iconClass}`}></i>
          </div>
          <h3 className="FeaturesIcons__title title has-text-weight-bold is-spaced is-5">
            {item.title}
          </h3>
          <p className="subtitle is-6 has-text-grey">{item.description}</p>
        </div>
      ))}
    </div>
  );
}

export default FeaturesIcons;
