import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import PageHeadSection from '../components/PageHeadSection';
import HeroSectionSimpleWhite from '../components/HeroSectionSimpleWhite';
import FeaturesSectionAlternated from './../components/FeaturesSectionAlternated';
import FeaturesSectionIcons from './../components/FeaturesSectionIcons';
import CtaSection from './../components/CtaSection';
import SEO from '../components/SEO';

import fargo from '../images/for-lease-for-sale.svg';

export const serviceImage = graphql`
  fragment serviceImage on File {
    childImageSharp {
      fixed(fit: INSIDE, height: 200, width: 300) {
        ...GatsbyImageSharpFixed_withWebp
        height
        width
      }
    }
    extension
    publicURL
  }
`;

/**
 * Save the images in the folder ./src/images/services and update the paths
 * (relative to ./src/images/) in this section. Then update the title and
 * descriptions down below.
 */
export const query = graphql`
  query {
    image1: file(relativePath: { eq: "services/landlordrep.png" }) {
      ...serviceImage
    }
    image2: file(relativePath: { eq: "services/timthumb.png" }) {
      ...serviceImage
    }
    image3: file(
      relativePath: { eq: "services/sellerrep.png" }
    ) {
      ...serviceImage
    }
    image4: file(relativePath: { eq: "services/tenantrep.png" }) {
      ...serviceImage
    }
  }
`;

function Image({ image, alt }) {
  if (image.extension === 'svg') return <img src={image.publicURL} alt={alt} />;
  return <Img fixed={image.childImageSharp.fixed} alt={alt} />;
}

function ServicesPage(props) {
  return (
    <Layout>
      <SEO title="Services" />
      <PageHeadSection title="Services" />
      <HeroSectionSimpleWhite
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Core Services"
        subtitle="Horizon Real Estate Group is here to assist you with your commercial real estate needs. Our services include traditional Seller/Landlord and Buyer/Tenant representation. In addition, we assist our clients with tax deferred real estate transactions through our affiliate Dakota REIT."
        data-aos="fade-up"
      />
      <FeaturesSectionAlternated
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        items={[
          {
            title: 'Landlord Representation',
            description:
              "We don’t just fill buildings, we do our due diligence and give careful consideration to tenant mix providing landlords with long term results.",
            image: <Image image={props.data.image1} alt="" />,
          },
          {
            title: 'Tenant Representation',
            description:
              "We take time to listen, collaborate and truly understand clients’ needs. We seek both on market and off market opportunities to provide our clients with the most information and best space for their businesses.",            
            image: <Image image={props.data.image2} alt="" />,
          },
          {
            title: 'Seller Representation',
            description:
              "Our agents are trained in finance and marketing. We are creative thinkers and problem solvers. We consider highest and best use and how to position a property to achieve the best outcomes for our sellers. We don't just shoot from the hip. Horizon is a data-driven company and keeps a pulse on the market and where it is going. With our affiliate DAKOTA REIT, the door is open for tax-deferred exchanges.",
            image: <Image image={props.data.image3} alt="" />,
          },
          {
            title: 'Buyer Representation',
            description: 'Whether you are a real estate investor looking for interesting investment opportunities or a a business owner seeking a site specific to your business’ needs, we are here to provide you the high quality, detail-oriented service you are looking for. Our relationships provide us with a unique opportunity to find on and off market properties to suit your needs. We recognize real estate is not a one size fits all industry and provide our clients with flexibility, creativity, and reliability. We think long term considering the entire cycle of the deal from concept planning to the future disposition of the property. We equip you with all the information you need to be confident in your purchase.',
            image: <Image image={props.data.image4} alt="" />,
          },
        ]}
      />
      <FeaturesSectionIcons
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        image={fargo}
      />
      <CtaSection
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Ready to get started?"
        actionText="Contact Us"
        actionURL="/contact-us"
      />
    </Layout>
  );
}

export default ServicesPage;
